import React from "react";
import { Section } from "../types/section";
import { useAppContext } from "../contexts/app-context";

const FAQsSection = () => {
  const { websiteReleaseDate } = useAppContext();

  const faqs = [
    {
      question: "What should I wear?",
      answer:
        "The dress code is formal attire. We want everyone to look their best!",
    },
  ];

  if (new Date() >= websiteReleaseDate) {
    faqs.push({
      question: "Is there a gift registry?",
      answer: "Yes, please check out this link for more details.",
    });
  }

  return (
    <section id={Section.Faq} className="faqs-section">
      <h2 className="faqs-title">Frequently Asked Questions</h2>
      <div className="faqs-list">
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <h3 className="faq-question">{faq.question}</h3>
            <p className="faq-answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQsSection;
